/* 
 * @Author: 曹俊杰 
 * @Date: 2020-12-14 16:18:45
 * @Module: 消息通知
 */
 <template>
  <div class="notice">
    <div class="notice-center">
      <el-container class="user-content">
        <el-aside width="340px">
          <loginmodule />
          <notice-nav />
        </el-aside>
        <el-main width="884px"
                 style="padding:0px;margin-left:16px;box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);background:#fff;">
          <router-view />
        </el-main>
      </el-container>
    </div>
  </div>
</template>
 <script>
import loginmodule from "@/views/login/loginmodule"
import noticeNav from "./module/noticeNav"
export default {
  components: {
    loginmodule,
    noticeNav
  },
  data () {
    return {};
  },
  mounted () { },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
.notice {
  &-center {
    width: 1240px;
    margin: 0 auto;
  }
  background: #f7f7f7;
  padding-top: 76px;
  min-height: 100vh;
}
</style>