<template>
  <div>
    <div class="upImageFile">
      <el-upload
        accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
        :action="action"
        list-type="picture-card"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :data="imageDataObj"
        :limit="6"
        multiple
        :on-change="change"
        :on-success="success"
        :before-upload="upload"
        :on-exceed="exceed"
        :class="{ hide: hideUploadEdit }"
        :file-list="fileList"
      >
        <div class="upImageFile-btn" slot="default">
          <i class="el-icon-plus" style="font-size:20px"></i>
          <div style="font-size:14px">上传照片</div>
        </div>
        <div slot="file" slot-scope="{ file }" class="file-item">
          <div class="cover-text" v-show="uidObject[file.uid].cover">
            封面图
          </div>
          <img
            :src="file.url"
            style="object-fit: cover;width:100px;height:100px;"
          />
          <span class="el-upload-list__item-actions">
            <span
              class="el-upload-list__item-preview"
              @click="handlePreview(file)"
            >
              <i class="el-icon-zoom-in"></i>
            </span>
          </span>
        </div>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible">
        <div>
          <img width="100%" :src="dialogImageUrl" alt="" />
          <div class="bot">
            <div class="bb">
              <div class="del" @click="handleRemove(file)">删除</div>
              <div class="sfmt" @click="hoverClick(file)">设为封面图</div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
    <div class="upVideoFile">
        <el-upload
          accept=".mp3,.wma,.avi,.rm,.rmvb,.flv,.mpg,.mov,.mkv,.mp4"
          :action="action1"
          list-type="picture-card"
          :on-preview="handlePreview1"
          :on-remove="handleRemove1"
          :data="videoDataObj"
          :limit="3"
          multiple
          :show-file-list="false"
          :on-change="change1"
          :on-success="success1"
          :before-upload="upload1"
          :on-exceed="exceed1"
          :class="{ hide: hideUploadEdit1 }"
          :file-list="fileList1"
        >
          <div class="upVideoFile-btn" slot="default">
          <i class="el-icon-plus" style="font-size:20px"></i>
          <div style="font-size:14px">上传视频</div>
        </div>
          <div slot="file" slot-scope="{ file }" class="file-item">
            <video
              :src="file.url"
              width="100px"
              style="background:#000;"
              height="100px"
            ></video>
             <span class="el-upload-list__item-actions">
            <span
              class="el-upload-list__item-preview"
              @click="handlePreview1(file)"
            >
              <i class="el-icon-zoom-in"></i>
            </span>
          </span>
          </div>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible1">
         <video :src="dialogImageUrl1"
             width="100%"
             controls
             style="background:#000;"></video>
             <div class="del" @click="handleRemove1(file)">删除</div>
        </el-dialog>
      </div>
    <div class="publishimage-info" slot="tip">
      还可以上传{{ 6 - img_count }}张图片、{{ 3 - video_count }}个视频
    </div>
  </div>
</template>
 <script>
export default {
  components: {},
  data() {
    return {
      hideUploadEdit: false, //是否隐藏图片上传框
      imageDataObj: {
        success_action_status: "200"
      },
      dialogVisible: false,
      dialogImageUrl: "",
      fileList: [],
      file: {},
      uidObject: {},
      action: "",
      fileList1: [],
      action1: "",
      hideUploadEdit1: false, //是否隐藏图片上传框
      videoDataObj: {
        success_action_status: "200"
      },
      dialogVisible1: false,
      dialogImageUrl1: "",
      uidObject1: {},
      fileName: ""
    };
  },
  created() {
    this._getAliyun();
    this._getAliyun1();
  },
  mounted() {
    console.log(this.img_count);
  },
  computed: {
    //  已选中图片数量
    img_count() {
      return this.fileList.filter(item => item.raw.type.substr(0, 5) == "image")
        .length;
    },
    video_count() {
      return this.fileList1.filter(
        item => item.raw.type.substr(0, 5) == "video"
      ).length;
    },
    // 完整度
    integrity() {
      let sum = 0;
      Object.keys(this.form).map(item => {
        if (!this.$u.test.isEmpty(this.form[item])) {
          sum++;
        }
      });
      sum = sum + this.img_count + this.video_count;
      return (100 / 14) * sum;
    }
  },
  methods: {
    showDialog() {
      this.dialogVisible1 = true;
    },
    async _getAliyun() {
      const {
        data: { policy, signature, accessid, host, dir }
      } = await this.$api.getAliyun({ type: "image" });
      this.action = host;
      console.log(this.action);
      Object.assign(this.imageDataObj, {
        policy,
        signature,
        OSSAccessKeyId: accessid,
        dir
      });
    },
    async _getAliyun1() {
      const {
        data: { policy, signature, accessid, host, dir }
      } = await this.$api.getAliyun({ type: "video" });
      this.action1 = host;
      Object.assign(this.videoDataObj, {
        policy,
        signature,
        dir,
        OSSAccessKeyId: accessid
      });
    },
    handlePreview(file) {
      //点击文件列表中已上传的文件时的钩子
      console.log(file);
      this.file = file;
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
      //文件列表移除文件时的钩子
      console.log(file, fileList);
      var newFileList = this.fileList.filter(item => {
        return file.uid !== item.uid;
      });
      this.fileList = newFileList;
      if (this.uidObject[file.uid].cover) {
        this.uidObject[this.fileList[0].uid].cover = true;
      }
      this.dialogVisible = false;
      this.hideUploadEdit = this.fileList.length >= 6;
    },
    handlePreview1(file) {
      //点击文件列表中已上传的文件时的钩子
      console.log(file);
      this.dialogImageUrl1 = file.url;
      this.dialogVisible1 = true;
    },
    handleRemove1(file, fileList) {
      console.log(file, fileList)
      //文件列表移除文件时的钩子
     var newFileList = this.fileList.filter((item) => {
        return file.uid !== item.uid
      })
      this.fileList1 = newFileList
      this.dialogVisible = false;
      this.hideUploadEdit1 = this.fileList.length >= 3;
    },
    getFileName(filename) {
      // 设置文件名字
      let getSuffix = filename => {
        let pos = filename.lastIndexOf(".");
        let suffix = "";
        if (pos != -1) {
          suffix = filename.substring(pos);
        }
        return suffix;
      };
      return (
        new Date().getTime() +
        Math.random()
          .toString(36)
          .substring(3, 20) +
        getSuffix(filename)
      );
    },
    upload(file) {
      const filename = this.getFileName(file.name);
      const copyFile = new File([file], `${filename}`);
      Object.assign(this.imageDataObj, {
        key: `image/${filename}`
      });
      this.$set(this.uidObject, file.uid, {
        cover: this.fileList.length == 1,
        path: `image/${filename}`
      });
      return copyFile;
    },
    exceed() {
      // 文件超出个数限制时的钩子
      this.$message.warning("最多只能上传6张图片");
    },
    change(file, fileList) {
      // 文件列表改变
      console.log(file);
      this.fileList = fileList;
      this.hideUploadEdit = this.fileList.length >= 6;
    },
    success(response, file, fileList) {
      console.log(response);
      console.log(file);
      console.log(fileList);
    },
    upload1(file) {
      console.log(file);
      const filename = this.getFileName(file.name);
      const copyFile = new File([file], `${filename}`);
      console.log(copyFile);
      Object.assign(this.videoDataObj, {
        key: `video/${filename}`
      });
     this.$set(this.uidObject, file.uid, { cover: this.fileList.length == 1, path: `video/${filename}` })
      return copyFile;
    },
    exceed1() {
      // 文件超出个数限制时的钩子
      this.$message.warning("最多只能上传3个视频");
    },
    change1(file, fileList) {
      // 文件列表改变
      console.log(file);
      console.log(fileList);
      this.fileList1 = fileList;
      this.hideUploadEdit1 = this.fileList1.length >= 3;
    },
    success1(response, file, fileList) {
      console.log(fileList);
     
    }, // 设为封面图
    hoverClick({ uid }) {
      Object.keys(this.uidObject).forEach(key => {
        if (key == uid) {
          this.uidObject[key].cover = true;
        } else {
          this.uidObject[key].cover = false;
        }
      });
      this.dialogVisible = false;
    }
  }
};
</script>
 <style lang='scss'>
.el-dialog__body {
  padding: 20px 20px 0 20px;
}
.fmt {
  margin-bottom: 10px;
}
.publishimage-info {
  margin: 6px 0 18px 0;
  color: #999;
  font-size: 12px;
}
.el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 104px;
  position: relative;
  background: #f7f7f7;
}
.el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  margin-right: 20px;
}

.bot {
  width: 100%;
  height: 60px;
  color: #fff;
  font-size: 12px;
  text-align: right;
  position: relative;
  .bb {
    display: flex;
    align-items: center;
    height: 60px;
    position: absolute;
    right: 0;
    .del {
      margin-right: 10px;
      width: 50px;
      height: 100%;
      text-align: center;
      line-height: 60px;
      cursor: pointer;
    }
    .sfmt {
      width: 90px;
      height: 32px;
      background: #2990f9;
      border-radius: 2px;
      text-align: center;
      line-height: 32px;
      cursor: pointer;
    }
  }
}
.btxt {
  position: absolute;
  top: 18px;
  left: 21px;
  font-size: 14px;
  color: #cccccc;
}
.hide .el-upload--picture-card {
  display: none;
}
.upImageFile {
  margin-top: 10px;
  &-btn {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #ccc;
    line-height: 20px;
    i {
      color: #ccc;
    }
  }
}
.file-item {
  position: relative;
}
.cover-text {
  position: absolute;
  width: 42px;
  line-height: 18px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  top: 4px;
  left: 4px;
  color: #fff;
  font-size: 10px;
  text-align: center;
}
.coverBtn {
  position: absolute;
  font-size: 10px;
  line-height: 18px;
  bottom: 18px;
  left: 0px;
  right: 0px;
  margin: auto;
  background: #000;
  width: 80px;
  height: 18px;
  border-radius: 4px;
}
.upVideoFile {
  margin-top: 10px;
  &-btn {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #ccc;
    line-height: 20px;
    i {
      color: #ccc;
    }
  }
}

</style>