/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-06 15:44:28
 * @Module: 创建专栏
 */
 <template>
  <div>

    <div class="addColumn-box">
      <el-form ref="formData"
               label-width="100px"
               :model="formData"
               :rules="rules">
        <el-form-item label="专栏标题:"
                      prop="title">
          <el-input v-model="formData.title"
                    maxlength="25"
                    show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="编辑封面:"
                      prop="cover">
          <add-column-from-cover v-model="formData.cover"
                                 :type="type" />
        </el-form-item>
      </el-form>
    </div>
    <div style="padding:0 10%;margin-top:-80px">
      <el-button type="primary"
                 @click="submitForm"
                 style="width:100%">确定</el-button>
    </div>
  </div>
</template>
 <script>
import addColumnFromCover from './addColumnFromCover'
export default {
  components: {
    addColumnFromCover
  },
  props: {
    type: {
      type: String
    }
  },
  data () {
    return {
      formData: {
        title: '',
        cover: ''
      },
      rules: {
        title: [
          { required: true, message: '请输入专栏标题', trigger: 'blur' },
        ],
        cover: [
          { required: true, message: '请输选择封面', trigger: 'blur' },
        ]
      }
    };
  },
  mounted () { },
  methods: {
    submitForm () {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          this._set_data({
            ...this.formData,
            type: this.type
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async _set_data (data) {
      const { info, status } = await this.$api.addColumnApi(data)
      if (status == 200) {
        // this.$router.go(-1)
        this.$emit("onSuccess")
        this.formData.title = ''
        this.formData.cover = ''
        this.$message.success(info)
      } else {
        this.$message.error(info)
      }
    }
  },
};
 </script>
 <style lang='scss' scoped>
.addColumn-box {
  margin: 0 auto;
  margin-top: 20px;
  width: 880px;
  height: 520px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 41px 50px;
  padding-right: 361px;
}
</style>