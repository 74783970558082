<template>
  <div class="bgimg">
    <img src="@/static/img/laboratory/laboratoryBg.png"
         alt />
    <div style="position: absolute;top: 0;width: 100%;">
      <div class="wrapper">
        <div class="headeBox">
          <div>
            <router-link to="/">
              <a href="javascript:void(0)"
                 class="left">
                <img class="cusImg"
                     src="@/static/img/laboratory/blacklogo.png"
                     alt="logo" />
              </a>
            </router-link>
          </div>
          <div>
            <ul class="right">
              <li class="item">
                <a href="https://ks.mcn-union.com">快手联营后台</a>
              </li>
              <li class="item">
                <a href="https://tt.mcn-open.com/">探探联营后台</a>
              </li>
              <li class="item">
                <a href="https://momo.mcn-open.com/">陌陌联营后台</a>
              </li>
              <li class="item"
                  @click="tiktok">
                <a href="#">抖音经纪后台</a>
              </li>
              <!-- <li class="item">
                <a href="http://ximalaya.mcn-open.com/">喜马拉雅联营后台</a>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
      <div style="position: relative;">
        <div class="contentBox">
          <div class="contentTextBox">
            <div class="textBox">
              <div class="textTitle">中能实验室</div>
              <div class="textContent">
                结算秒到账、数据逻辑清晰、主播管理轻松直观，
                <br />为业务助力。 <br />全程指导，帮助新人和小团队快速成长。
              </div>
            </div>
          </div>
        </div>
        <div class="contenImgBox">
          <img class="contenImg animate__animated animate__fadeInRight"
               src="https://oss.mcn-open.com/images/bg_pc.png"
               alt />
        </div>
      </div>
    </div>
    <el-dialog title="提示：您正在访问第三方链接"
               :visible.sync="visible"
               width="30%"
               :before-close="()=>visible = false"
               style="z-index:2004;">
      <div class="addr">网站地址：{{otherUrl}}</div>
      <div>您即将登录由第三方机构开发的管理后台</div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary"
                   @click="handleOk">确 定</el-button>
      </span>
    </el-dialog>
    <!-- <Modal :visible="visible"
           @handleOk="handleOk"
           @handleCancel="visible = false"
           title="提示：您正在访问第三方链接">
      <div class="modal-content">
        <div class="addr">网站地址：{{otherUrl}}</div>
        <div>您即将登录由第三方机构开发的管理后台</div>
      </div>
    </Modal> -->
  </div>
</template>

<script>
export default {
  components: {
  },
  name: "laboraHeader",
  data () {
    return {
      visible: false,
      otherUrl: ''
    };
  },
  methods: {
    tiktok () {
      this.visible = true
      this.otherUrl = 'https://union.bytedance.com/open/'
    },
    handleOk () {
      window.open(this.otherUrl);
      this.visible = false;
      console.log("ok");
    }
  }
};
</script>
<style lang="scss" scoped>
.modal-content {
  height: 78px;
  padding: 18px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
}

.bgimg {
  width: 100%;
  position: relative;
  height: 725px;

  img {
    width: 1162px;
  }

  .contentBox {
    width: 1240px;
    height: 588px;
    display: flex;
    justify-content: space-between;
    margin-top: 21px;
    margin: 0 auto;

    .contentTextBox {
      // margin-right : 129px;
      // width: 100%;
      // padding-right: 129px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .textTitle {
        font-size: 58px;
        font-weight: 500;
        color: #333333;
      }

      .textContent {
        margin-top: 20px;
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        line-height: 53px;
        width: 533px;
      }
    }
  }

  .contenImgBox {
    .contenImg {
      position: absolute;
      top: 0;
      right: 0;
      width: 678px;
      height: 588px;
      -webkit-backface-visibility: hidden;
    }
  }

  .wrapper {
    width: 100%;
    height: 80px;

    .headeBox {
      width: 1240px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 100%;
      align-items: center;
    }

    .left {
      .cusImg {
        width: 184px;
        height: 43px;
      }
    }

    .right {
      height: 0.8rem;
      line-height: 0.8rem;
      font-size: 16px;
      color: #dbdbdb;
      display: flex;

      .item {
        position: relative;
        // float left
        padding-left: 0.8rem;

        a {
          cursor: pointer;
          color: #333333;
          font-weight: 400;

          .cusImg {
            width: 184px;
            height: 43px;
          }
        }

        .beta {
          font-size: 0.8rem;
          line-height: 0.8rem;
          margin-left: -0.4rem;
        }
      }
    }
  }
}

.addr {
  word-break: break-all;
  line-height: 16px;
  margin-bottom: 10px;
}
</style>
<style scoped>
@media screen and (max-width: 1240px) {
  .wrapper {
    /* margin: 0 auto; */
    min-width: 1240px;
  }
  .contentBox {
    min-width: 1240px;
  }
}
</style>