<template>
  <div class="upImageFile">
    <el-upload
      :action="action"
      accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
      list-type="picture-card"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :data="dataObj"
      :limit="6"
      multiple
      :file-list="fileList"
      :on-change="change"
      :before-upload="upload"
      :class="{ hide: hideUploadEdit }"
      :on-exceed="exceed"
    >
      <div class="upImageFile-btn" slot="default">
        <i class="el-icon-plus" style="font-size:20px"></i>
        <div style="font-size:14px">上传照片</div>
      </div>
      <div slot="file" slot-scope="{ file }" class="file-item">
        <div class="cover-text" v-show="file.url == coverpath">封面图</div>
        <img
          :src="file.url"
          style="object-fit: cover;width:100px;height:100px;"
        />
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
      <div class="bot">
        <div class="bb">
          <div class="del" @click="handleRemove(file)">删除</div>
          <div class="sfmt" @click="hoverClick(file)">设为封面图</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
 <script>
export default {
  components: {},
  props: {
    fileList: {
      type: Array,
      default: () => []
    },
    coverpath: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      file: {},
      action: "",
      dataObj: {
        success_action_status: "200"
      },
      dialogVisible: false,
      dialogImageUrl: "",
      hideUploadEdit: false, //是否隐藏图片上传框
      uidObject: {}
    };
  },
  created() {
    this._getAliyun();
  },
  mounted() {
    console.log(this.fileList);
  },
  computed: {},
  methods: {
    // 获取阿里云配置
    async _getAliyun() {
      const {
        data: { policy, signature, accessid, host }
      } = await this.$api.getAliyun({ type: "image" });
      this.action = host;
      Object.assign(this.dataObj, {
        policy,
        signature,
        OSSAccessKeyId: accessid
      });
    },
    getFileName(filename) {
      // 设置文件名字
      let getSuffix = filename => {
        let pos = filename.lastIndexOf(".");
        let suffix = "";
        if (pos != -1) {
          suffix = filename.substring(pos);
        }
        return suffix;
      };
      return (
        new Date().getTime() +
        Math.random()
          .toString(36)
          .substring(3, 20) +
        getSuffix(filename)
      );
    },
    upload(file) {
      //文件上传前的回调
      console.log(file);
      const filename = this.getFileName(file.name);
      const copyFile = new File([file], `${filename}`);
      Object.assign(this.dataObj, {
        key: `image/${filename}`
      });
      this.$set(this.uidObject, file.uid, {
        cover: this.fileList.length == 1,
        path: `image/${filename}`
      });
      return copyFile;
    },
    exceed() {
      // 文件超出个数限制时的钩子
      this.$message.warning("最多只能上传6张图片");
    },
    change(file, fileList) {
      // 文件列表改变
      this.$emit("setfileList",fileList)
      this.hideUploadEdit = this.fileList.length >= 6;
    },
    handlePictureCardPreview(file) {
      //点击文件列表中已上传的文件时的钩子
      console.log(file);
      this.file = file;
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
      //文件列表移除文件时的钩子
      console.log(file, fileList);
      var newFileList = this.fileList.filter(item => {
        return file.uid !== item.uid;
      });
      this.fileList = newFileList;
      if (file.url == this.coverpath) {
        this.$emit("setcoverpath", this.fileList[0].url);
      }
      this.dialogVisible = false;
      this.hideUploadEdit = this.fileList.length >= 6;
      console.log(newFileList);
    },
    // 设为封面图
    hoverClick({ url }) {
      this.$emit("setcoverpath", url);
      this.dialogVisible = false;
    }
  },
  watch: {
    fileList: {
      handler: function(val) {
        this.$emit("count", val.length);
      },
      // 深度观察监听
      deep: true,
      immediate: true
    }
  }
};
</script>
 <style lang='scss'>
.el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 104px;
  position: relative;
  background: #f7f7f7;
}
.el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  margin-right: 20px;
}
.hide .el-upload--picture-card {
  display: none;
}
.upImageFile {
  &-btn {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #ccc;
    line-height: 20px;
    i {
      color: #ccc;
    }
  }
}
.file-item {
  position: relative;
}
.cover-text {
  position: absolute;
  width: 42px;
  line-height: 18px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  top: 4px;
  left: 4px;
  color: #fff;
  font-size: 10px;
  text-align: center;
}
.coverBtn {
  position: absolute;
  font-size: 10px;
  line-height: 18px;
  bottom: 18px;
  left: 0px;
  right: 0px;
  margin: auto;
  background: #000;
  width: 80px;
  height: 18px;
  border-radius: 4px;
}
.bot {
  width: 100%;
  height: 60px;
  color: #fff;
  font-size: 12px;
  text-align: right;
  position: relative;
  .bb {
    display: flex;
    align-items: center;
    height: 60px;
    position: absolute;
    right: 0;
    .del {
      margin-right: 10px;
      width: 50px;
      height: 100%;
      text-align: center;
      line-height: 60px;
      cursor: pointer;
    }
    .sfmt {
      width: 90px;
      height: 32px;
      background: #2990f9;
      border-radius: 2px;
      text-align: center;
      line-height: 32px;
      cursor: pointer;
    }
  }
}
</style>