<template>
  <!-- 
	*以数据驱动的card式展示图片（无动画效果）
	*  -->
  <div>
    <div class="stitle">
      <span>王牌运营</span>
      <p>点击卡片向其推荐主播</p>
    </div>
    <div class="cardBanner">
      <ul>
        <li v-for="(item, index) in cardData" :key="index">
          <div
            @mouseenter="flag = index"
            @mouseleave="flag = -1"
            @click="goTj(item.user_id)"
          >
            <div class="Img filter" ref="filter">
              <img :src="item.pc_image" alt="" />
              <div class="ftop">
                <p>向Ta推荐主播</p>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="bott">
        <div class="arrow-left" @click="toggleFun(-1)">
          <img src="@/static/starImg/larrow.png" alt="" class="hover" />
        </div>
        <span style="color:#DF2A29">{{ num | numFilter }}</span>
        <div
          style="width:38px;height:6px;border-bottom:1px solid #4D4D4D;margin:2px 10px 0 10px;"
        ></div>
        <span>{{ (cardData.length - 1) | numFilter }}</span>
        <div class="arrow-right" @click="toggleFun(1)">
          <img src="@/static/starImg/rarrow.png" alt="" class="hover" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      flag: "",
      num: 0
    };
  },
  filters: {
    numFilter(num) {
      return num < 9 ? "0" + (num + 1) : num + 1;
    }
  },
  props: {
    cardData: {
      type: Array,
      default: () => []
    }
  },
  created() {},
  methods: {
    goTj(id) {
      const { href } = this.$router.resolve({
        path: "/homepage/user/personal",
        query: {
          uuid: id
        }
      });
      window.open(href, "_blank"); //打开新的窗口
    },
    //通过函数改变数据从而达到视图的改变
    toggleFun(p) {
      this.cardData = this.cardData.map((item, index, array) => {
        if (index === array.length - 1 && p === 1) {
          item = array[0];
          if (this.num == this.cardData.length - 1) {
            this.num = 0;
          } else {
            this.num += 1;
          }
        } else if (index === 0 && p === -1) {
          item = array[array.length - 1];
          if (this.num == 0) {
            this.num = this.cardData.length - 1;
          } else {
            this.num -= 1;
          }
        } else {
          item = array[index + p];
        }
        return item;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cardBanner {
  position: relative;
}
.stitle {
  height: 56px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  span {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    margin-right: 10px;
  }
  p {
    font-size: 14px;
    color: #999999;
    margin-top: 2px;
  }
}
.cardBanner ul {
  display: flex;
  overflow: scroll; /*设置滚动条*/
}
.cardBanner ul::-webkit-scrollbar {
  /*隐藏滚动条*/
  display: none;
}
.cardBanner ul > li {
  width: 31.33333%;
  flex-shrink: 0;
  margin-right: 25px;
  border-radius: 4px;
  background: #fff;
  position: relative;
  cursor: pointer;
}
.cardBanner ul > li:first-child {
  padding-left: 0;
}
.cardBanner ul > li a {
  display: block;
  width: 100%;
  height: 100%;
}
.cardBanner ul > li .Img {
  width: 278px;
  height: 386px;
  border-radius: 5px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    transition: all 1s;
    background: #fff;
  }
  &:hover {
    img {
      filter: blur(7px);
    }
    .ftop {
      opacity: 1;
    }
  }
  .ftop {
    transition: all 1s;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 2px;
    top: 0;
    opacity: 0;
    text-align: center;
    line-height: 386px;
    font-family: YouSheBiaoTiHei-2;
    color: #fff;
    font-size: 26px;
    background: rgba(0, 0, 0, 0.2);
    // p{
    //   height: 20px;
    //   margin-bottom: 10px;;
    // }
  }
}
.cardBanner ul > li .dd {
  height: 68px;
  width: 262px;
  display: flex;
  align-items: center;
  .desc {
    height: 44px;
    font-size: 15px;
    color: #333333;
    white-space: normal !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    padding: 0 10px;
    line-height: 22px;
  }
}
.bott {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  .arrow-left {
    width: 39px;
    height: 11px;
    margin-right: 10px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .arrow-right {
    width: 39px;
    height: 11px;
    margin-left: 10px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>