/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-25 16:00:26
 * @Module: 好友列表
 */
 <template>
  <div class="chatFriendBox">
    <div class="chatFriendBox-search">
      <el-autocomplete style="width:100%"
                       placeholder="搜索联系人"
                       suffix-icon="el-icon-search"
                       v-model="keyWord"
                       value-key="nick_name"
                       @select="handleSelect"
                       :fetch-suggestions="querySearch"
                       clearable
                       :trigger-on-focus="false">
      </el-autocomplete>
    </div>
    <div class="chatFriendBox-title">
      <span>聊天列表</span>
      <el-dropdown trigger="click"
                   @command="handleCommand">
        <span class="el-icon-plus hover"></span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="addFriend">添加好友</el-dropdown-item>
          <el-dropdown-item command="addGroup">创建群聊</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="chatFriendBox-list"
         v-loading="friendListLoading">
      <!-- 搜索好友列表 -->
      <div v-show="keyWord">
        <chatFriendItem :list="searchFriendList" />
      </div>
      <!-- 好友列表 -->
      <div v-show="!keyWord">
        <chatFriendItem :list="friendList" />
        <!-- <div class="chatFriendBox-list-item hover"
             :class="{active:active==item.user_id}"
             v-for="(item) in friendList"
             :key="item.key"
             @click="to_chat(item)">
          <div class="chatFriendBox-list-item-l">
            <el-avatar :size="50"
                       :src="item.avatar"></el-avatar>
          </div>
          <div class="chatFriendBox-list-item-r">
            <div class="chatFriendBox-list-item-r-t">
              <div class="chatFriendBox-list-item-r-t-l">{{item.nick_name}}</div>
              <div class="chatFriendBox-list-item-r-t-r">{{item.latest_time}}</div>
            </div>
            <div class="chatFriendBox-list-item-r-b">
              <div class="chatFriendBox-list-item-r-b-l ellipsis">{{item.last_message}}</div>
              <div class="chatFriendBox-list-item-r-b-r "
                   v-show="item.unread_count">{{item.unread_count}}</div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <userCheckbox ref="userCheckbox"
                  title="创建群聊" />
  </div>
</template>
 <script>
import { mapState } from "vuex";
import chatFriendItem from "./chatFriendItem"
import userCheckbox from "@/views/homepage/chat/module/userCheckbox"
export default {
  components: {
    chatFriendItem,
    userCheckbox
  },
  data () {
    return {
      active: null,
      keyWord: '',
      searchFriendList: [],
      friendListLoading: false,
      friendList: [{
        key: 1,
        avatar: 'https://oss.mcn-open.com/images/AFfWluPvmlUhpEeStMyNrmlATsxmgcD3hIgTQ1Jd.jpeg',
        nick_name: '11222',
        latest_time: '2021年3月2日',
        last_message: '111',
        unread_count: 11
      }]
    };
  },
  computed: {
    ...mapState({
      // friendList: (state) => state.jimSdk.messageList,
      jimIsLogin: (state) => state.jimSdk.jimIsLogin
    }),
    // searchFriendList () {
    //   const { keyWord } = this
    //   if (keyWord) {
    //     return this.friendList.find(item => {
    //       return item.nick_name.includes(keyWord)
    //     })
    //   } else {
    //     return []
    //   }
    // }
  },
  mounted () {
    this._get_list()
  },
  methods: {
    handleSelect (item) {
      this.searchFriendList = [item]
    },

    querySearch (queryString, cb) {
      var restaurants = this.friendList;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      this.searchFriendList = results
      cb(results);
    },
    createFilter (queryString) {
      return (restaurant) => {
        return (restaurant.nick_name.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
      };
    },
    handleCommand (command) {
      if (command == "addGroup") {
        this.$refs.userCheckbox.setDialogVisible(true)
      }
    },
    async _get_list () {
      // const aa = await this.$api.chatConvosListApi()
      // console.log(aa)
    },
  }
};
 </script>
 <style lang='scss' scoped>
.chatFriendBox {
  height: 780px;
  width: 320px;
  padding: 20px 0px 35px 0px;
  background: #fff;
  border-right: #eee 1px solid;
  &-search {
    padding: 0 20px;
  }
  &-title {
    margin-top: 20px;
    color: #333333;
    font-size: 15px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
  }
  &-list {
    margin-top: 10px;
    overflow: auto;
    height: 675px;
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #cccedb;
    }
    &::-webkit-scrollbar-track {
      display: none;
    }
  }
}
.active {
  background: #f9f9f9;
  border-left: solid 1px #df2a29;
}
</style>