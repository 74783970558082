/* 
 * @Author: 曹俊杰 
 * @Date: 2020-12-14 17:36:29
 * @Module: 互动消息
 */
 <template>
  <div class="box"
       v-loading="loading">
    <div class="item hover"
         v-for="(item,index) in list"
         @click="()=>to_detail(item)"
         :key="index">
      <div class="item-l">
        <div @click.stop="()=>to_personal(item)">
          <el-avatar :size="48"
                     :src="item.user_avatar"></el-avatar>
        </div>
        <div class="item-l-content">
          <div class="item-l-content-t">
            <span class="item-l-content-t-1">{{item.user_name}} </span>
            <span class="item-l-content-t-2">回复了你</span>
          </div>
          <div class="item-l-content-c">{{item.content}}</div>
          <div class="item-l-content-b">{{item.created_at}}</div>
        </div>
      </div>
      <div class="item-r">
        <el-image style="width: 60px;height: 60px;border-radius: 2px;"
                  :src="item.commentable.moment_cover"
                  v-if="item.commentable&&item.commentable.moment_cover" />
        <div v-else
             class="item-r-text">{{item.commentable?item.commentable.moment_title:''}}</div>
      </div>
    </div>
    <el-pagination layout="prev, pager, next"
                   style="display: flex;justify-content: center;"
                   v-show="pagination.page"
                   :current-page="pagination.page"
                   @current-change="currentChange"
                   hide-on-single-page
                   :page-size="pagination.pageSize"
                   :total="pagination.total">
    </el-pagination>
    <no-data v-show="pagination.total==0&& !loading">暂无评论</no-data>
  </div>
</template>
 <script>
import noData from "@/components/noData"
export default {
  components: {
    noData
  },
  data () {
    return {
      loading: true,
      list: [],
      pagination: {
        total: 0,
        pageSize: 20,
        page: null
      },
    };
  },
  mounted () {
    this._get_list()
  },
  methods: {
    to_detail ({ commentable: { commentable_uuid: uuid, moment_type: type, moment_shareable_uuid: course_id } }) {
      this.$store.dispatch("homepage/toDetail", {
        type, uuid, query: {
          course_id
        }
      })
    },
    to_personal ({ user_id: uuid }) {
      this.$store.dispatch("homepage/toPersonal", { uuid })
    },
    currentChange (page) {
      this._get_list(page)
    },
    async _get_list (page = 1) {
      this.loading = true
      const { status, info, data, meta } = await this.$api.userCommentListApi({ page })
      this.loading = false
      if (status == 200) {
        this.list = data
        this.pagination.total = meta.pagination.total
        this.pagination.pageSize = meta.pagination.per_page
        this.pagination.page = meta.pagination.current_page
      } else {
        this.$message.error(info)
      }

    },
  },
};
 </script>
 <style lang='scss' scoped>
@import "../style.scss";
</style>