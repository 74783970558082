/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-06 16:03:53
 * @Module: 创建专栏上传封面模块
 */
 <template>
  <div class="fromCover-box">
    <el-upload action="/api/moment/upload-media"
               :limit="1"
               name="media"
               :data="{type}"
               ref="upload"
               :headers="headers"
               :before-upload="upload"
               :on-success="success"
               :on-error="error"
               :show-file-list="false"
               style="height:90px">
      <el-image style="width: 161px; height: 90px;border-radius: 4px;"
                :src="'http://meimengapp.oss-cn-beijing.aliyuncs.com/' +value"
                fit="cover"
                v-if="value"></el-image>
      <div v-else
           class="addBtn"
           v-loading="loading">
        <div class="el-icon-plus"
             style="font-size:22px;margin-bottom:6px" />
        <div>上传封面</div>
      </div>
    </el-upload>
    <div class="fromCover-text">封面格式JPG、JPEG、PNG， 建议以1920*1080为参考比例</div>
  </div>
</template>
 <script>
import userInfo from "@/mixins/userinfo"
export default {
  props: {
    value: {
      type: String
    },
    type: {
      type: String
    }
  },
  mixins: [userInfo],
  components: {},
  data () {
    return {
      loading: false
    };
  },
  mounted () {
  },
  computed: {
    headers () {
      return {
        Authorization: 'Bearer ' + this.userInfo.token
      }
    }
  },
  methods: {
    getFileName (filename) {    // 设置文件名字
      let getSuffix = (filename) => {
        let pos = filename.lastIndexOf('.');
        let suffix = '';
        if (pos != -1) {
          suffix = filename.substring(pos);
        }
        return suffix;
      };
      return (
        new Date().getTime() +
        Math.random()
          .toString(36)
          .substring(3, 20) +
        getSuffix(filename)
      );
    },
    upload (file) {   //文件上传前的回调
      console.log(file)
      if (file.type.split('/')[0] !== 'image') {
        this.$message.error("请选择正确的格式。")
        return false
      }
      const filename = this.getFileName(file.name)
      const copyFile = new File([file], `${filename}`)
      this.loading = true
      return copyFile
    },
    success (response) {
      const { data, status, info } = response
      this.loading = false
      if (status == 200) {
        this.$emit('input', data.path)
        this.$refs.upload.clearFiles()
      } else {
        this.$message.error(info)
      }
    },
    error () {
      this.loading = false
    }
  },
};
 </script>
 <style lang='scss' scoped>
.fromCover-box {
  height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
}
.fromCover-text {
  margin-left: 22px;
  width: 170px;
  color: #999;
  font-size: 12px;
  line-height: 17px;
}
.addBtn {
  width: 161px;
  height: 90px;
  background: #f7f7f7;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  color: #999;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}
</style>