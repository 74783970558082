/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-04 15:41:06
 * @Module: 发布微头条
 */
 <template>
  <div class="publishimage-box">
    <Mentionable :keys="['@','#']"
                 :items="items"
                 insert-space
                 ref="at"
                 @open="onOpen"
                 offset="6">
      <el-input type="textarea"
                :autosize="{minRows:6}"
                show-word-limit
                placeholder="有什么新想事想告诉大家"
                resize="none"
                v-model="content">
      </el-input>
    </Mentionable>
    <div>
      <el-upload :action="action"
                 list-type="picture-card"
                 :on-preview="handlePictureCardPreview"
                 :on-remove="handleRemove"
                 :data="dataObj"
                 :limit="9"
                 multiple
                 :on-change="change"
                 :on-success="success"
                 :before-upload="upload"
                 :on-exceed="exceed">
        <i class="el-icon-plus"></i>
      </el-upload>
      <div class="publishimage-info">共{{fileList.length}}张，还能上传{{9-fileList.length}}张</div>
      <datePicker ref="datePicker"
                  style="margin-top:20px;" />
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%"
             :src="dialogImageUrl"
             alt="">
      </el-dialog>
    </div>
  </div>
</template>
 <script>
import datePicker from "./datePicker"
import administrator from "@/mixins/administrator"
import Mentionable from '@/components/mentionable'
import userinfo from "@/mixins/userinfo"
export default {
  components: {
    datePicker,
    Mentionable
  },
  data () {
    return {
      followedList: [],
      text: '',
      content: '',
      action: '',
      dataObj: {
        success_action_status: '200',
      },
      dialogVisible: false,
      dialogImageUrl: '',
      fileList: [],
      uidObject: {},
      atSelect: [],
      items: []
    };
  },
  mixins: [administrator, userinfo],
  created () {
  },
  mounted () {
    this._getAliyun()
    this._get_followedList()
  },
  computed: {
    //@ 的下拉菜单
    users () {
      return this.followedList.map(item => ({ value: item.nick_name, firstName: item.uuid, user_id: item.user_id }))
    },
    issues () {
      return []
    }
  },
  methods: {
    onOpen (key) {
      this.items = key === '@' ? this.users : this.issues
    },
    //获取关注列表
    async _get_followedList () {
      const { data, status } = await this.$api.followedListApi({ uuid: this.userInfo.uuid })
      if (status === 200)
        this.followedList = data
    },
    async _getAliyun () {
      const { data: {
        policy,
        signature,
        accessid,
        host
      } } = await this.$api.getAliyun({ type: 'image' })
      this.action = host
      Object.assign(this.dataObj, {
        policy,
        signature,
        OSSAccessKeyId: accessid
      })
    },
    handlePictureCardPreview (file) {   //点击文件列表中已上传的文件时的钩子
      console.log(file)
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove (file, fileList) {  //文件列表移除文件时的钩子
      console.log(file, fileList)
    },
    getFileName (filename) {    // 设置文件名字
      let getSuffix = (filename) => {
        let pos = filename.lastIndexOf('.');
        let suffix = '';
        if (pos != -1) {
          suffix = filename.substring(pos);
        }
        return suffix;
      };
      return (
        new Date().getTime() +
        Math.random()
          .toString(36)
          .substring(3, 20) +
        getSuffix(filename)
      );
    },
    upload (file) {   //文件上传前的回调
      console.log(file)
      const filename = this.getFileName(file.name)
      const copyFile = new File([file], `${filename}`)
      Object.assign(this.dataObj, {
        key: `image/${filename}`
      })
      this.uidObject[file.uid] = `image/${filename}`
      return copyFile
    },
    exceed () {  // 文件超出个数限制时的钩子
      this.$message.warning("最多只能上传9张图片")
    },
    change (file, fileList) {   // 文件列表改变
      this.fileList = fileList
    },
    success (response, file, fileList) {
      console.log(response)
      console.log(file)
      console.log(fileList)
    },
    onPublish () {    //点击发布按钮
      let medias = []
      const mentions = this.get_mentions()
      this.fileList.forEach(item => {
        medias.push(this.uidObject[item.uid])
      })
      this._set_data({
        content: this.content,
        medias,
        mentions
      })
    },
    async _set_data (data) {
      if (this.administrator || this.$refs.datePicker.value) {
        data.published_at = this.$refs.datePicker.value
      }
      const { info, status } = await this.$api.publishImageApi(data)
      if (status == 200) {
        this.$router.go(-1)
        this.$message.success(info)
      } else {
        this.$message.error(info)
      }
    },
    //  获取At的人
    get_mentions () {
      function getExecStrs (str) {
        var reg = /@(.+?)\s/g
        var list = []
        var result = null
        do {
          result = reg.exec(str)
          result && list.push(result[1])
        } while (result)
        return list
      }
      var str = this.content
      let atUserList = getExecStrs(str)
      console.log(this.$refs.at)
      let mentions = []
      atUserList.forEach(item => {
        this.followedList.forEach(itm => {
          if (item == itm.nick_name) {
            mentions.push({
              user_id: itm.user_id,
              nick_name: item,
              uuid: itm.uuid
            })
          }
        })
      })
      return mentions
    },
  },
};
 </script>
 <style lang='scss' scoped>
.publishimage-box {
  min-height: 520px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 30px 50px;
}
.publishimage-box /deep/.el-textarea__inner {
  border: none;
  padding: 0px;
}
.publishimage-info {
  margin-top: 12px;
  color: #999;
  font-size: 12px;
}
.user :hover {
  background: #f66 !important;
}
</style>