/* 
 * @Author: 曹俊杰 
 * @Date: 2020-12-29 16:56:04
 * @Module: 视频截取
 */
 <template>
  <el-dialog :visible.sync="visible"
             v-loading="loading"
             width="500px">
    <video :src="videopath"
           @loadedmetadata="getVideoLength"
           width="100%"
           height="220px"
           ref="video"></video>
    <el-slider v-model="extent"
               :max="videoLength"
               :show-tooltip="false"
               @input="changeVideoTime"></el-slider>
    <div class="videoCoverCut-footer">
      <el-button type="primary"
                 size="small"
                 @click="okFn">确定</el-button>
    </div>
  </el-dialog>
</template>
 <script>
export default {
  components: {},
  props: {
    videopath: {
      type: String
    }
  },
  data () {
    return {
      visible: false,
      videoLength: 0,
      extent: 0,
      loading: true
    };
  },
  mounted () { },
  methods: {
    switchVisible () {
      this.visible = !this.visible
    },
    getVideoLength () {//获取音视频时长 1音频 2视频
      this.videoLength = parseInt(this.$refs.video.duration) * 1000
      this.loading = false
    },
    changeVideoTime (val) {//改变视频时间
      if (this.$refs.video) {
        this.$refs.video.currentTime = val / 1000;
      }

    },
    okFn () {
      this.switchVisible()
      this.$emit("success", this.extent)
    }
  },
};
 </script>
 <style lang='scss' scoped>
.videoCoverCut-footer {
  display: flex;
  justify-content: flex-end;
}
</style>