/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-06 14:28:07
 * @Module: 选择封面组件
 */
 <template>
  <div class="fromCover-box">
    <el-image style="width: 161px;border-radius: 4px;"
              :src="action+value"
              fit="cover"
              @click="coverSelectVisible=true"></el-image>
    <div class="tihuanBtn">替换</div>

    <div class="fromCover-text">{{hint}}</div>
    <!-- 选择上传本地图片 或者 截取图片 -->
    <el-dialog :visible.sync="coverSelectVisible"
               width="260px">
      <el-button class="btnStyle"
                 @click="videoCoverCutClick">截取视频图片</el-button>
      <el-upload :action="action"
                 :limit="1"
                 :data="dataObj"
                 ref="upload"
                 :before-upload="upload"
                 :on-success="success"
                 :show-file-list="false">
        <el-button class="btnStyle">选择本地图片</el-button>
      </el-upload>
    </el-dialog>
    <video-cover-cut ref="videoCoverCut"
                     :videopath="videoaction+videopath"
                     @success="coverCutSuccess" />
  </div>
</template>
 <script>
import videoCoverCut from "./videoCoverCut"
export default {
  props: {
    value: {
      type: String
    },
    videopath: {
      type: String
    },
    videoaction: {
      type: String
    },
    hint: {
      type: String,
      default: "封面格式JPG、JPEG、PNG， 建议以1920*1080为参考比例"
    }
  },
  components: {
    videoCoverCut
  },
  data () {
    return {
      action: '',
      dataObj: {
        success_action_status: '200',
      },
      path: '',
      coverSelectVisible: false
    };
  },
  mounted () {
    this._getAliyun()
  },
  methods: {
    async _getAliyun () {
      const { data: {
        policy,
        signature,
        accessid,
        host,
        dir
      } } = await this.$api.getAliyun({ type: 'image' })
      this.action = host
      Object.assign(this.dataObj, {
        policy,
        signature,
        dir,
        OSSAccessKeyId: accessid
      })
    },
    getFileName (filename) {    // 设置文件名字
      let getSuffix = (filename) => {
        let pos = filename.lastIndexOf('.');
        let suffix = '';
        if (pos != -1) {
          suffix = filename.substring(pos);
        }
        return suffix;
      };
      return (
        new Date().getTime() +
        Math.random()
          .toString(36)
          .substring(3, 20) +
        getSuffix(filename)
      );
    },
    upload (file) {   //文件上传前的回调
      console.log(file)
      if (file.type.split('/')[0] !== 'image') {
        this.$message.error("请选择正确的格式。")
        return false
      }
      const filename = this.getFileName(file.name)
      const copyFile = new File([file], `${filename}`)
      Object.assign(this.dataObj, {
        key: `${this.dataObj.dir}${filename}`
      })
      this.path = `${this.dataObj.dir}${filename}`
      return copyFile
    },
    success () {
      this.coverSelectVisible = false
      this.$emit('input', `${this.path}`)
      this.$refs.upload.clearFiles()
    },
    videoCoverCutClick () {
      this.$refs.videoCoverCut.switchVisible()
    },
    coverCutSuccess (t) {
      this.coverSelectVisible = false
      this.$emit('input', `${this.videopath}?x-oss-process=video/snapshot,t_${t},f_jpg`)
    }
  },
};
 </script>
 <style lang='scss' scoped>
.fromCover-box {
  // height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.fromCover-text {
  margin-left: 22px;
  width: 170px;
  color: #999;
  font-size: 12px;
  line-height: 17px;
}
.tihuanBtn {
  color: #fff;
  left: 120px;
  bottom: 8px;
  height: 18px;
  line-height: 18px;
  position: absolute;
  font-size: 12px;
  padding: 0px 5px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.5);
}
.btnStyle {
  width: 200px;
  margin: 10px;
}
</style>