import { render, staticRenderFns } from "./tjInfo.vue?vue&type=template&id=d4277e50&scoped=true&"
import script from "./tjInfo.vue?vue&type=script&lang=js&"
export * from "./tjInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4277e50",
  null
  
)

export default component.exports