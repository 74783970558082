/* 
 * @Author: 曹俊杰 
 * @Date: 2020-09-09 10:59:48
 * @Module: 查询通用头部 
 */
 <template>
  <div class="header">
    <router-link to="/">
      <img src="@/static/img/laboratory/blacklogo.png"
           v-if="search"
           style="width:184px;"
           class="pointer"
           alt="网红头条logo" />
      <img src="@/static/wanghong/pc-logo.png"
           v-else
           class="pointer"
           alt="网红头条logo" />
    </router-link>
    <!-- <div class="header-search" v-if="search">
      <div class="header-search-input">
        <i class="iconfont iconsousuo"></i>
        <input type="text" placeholder="搜索微信号、电话号" />
      </div>
      <div class="header-search-btn">查一下</div>
    </div> -->
  </div>
</template>
 <script>
export default {
  name: "resultheader",
  props: {
    search: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {};
  },
  components: {},
  mounted () { },
  methods: {},
};
</script>
 <style lang="scss" scoped>
.header {
  height: 80px;
  min-width: 1240px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    margin-left: 100px;
  }
  &-search {
    width: 465px;
    height: 36px;
    margin-right: 100px;
    display: flex;
    &-btn {
      width: 80px;
      background: #4b9cfc;
      border-radius: 0px 2px 2px 0px;
      text-align: center;
      line-height: 36px;
      color: #fff;
      font-size: 16px;
    }
    &-input {
      width: 385px;
      height: 36px;
      border-radius: 2px 0px 0px 2px;
      border: 1px solid #e3e3e3;
      border-right: none;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      i {
        color: #4b9cfc;
        margin-left: 10px;
        margin-right: 6px;
      }
      input {
        flex: 1;
      }
    }
  }
}
</style>