/* 
 * @Author: 曹俊杰 
 * @Date: 2021-01-11 15:50:58
 * @Module: 格调学院
 */
 <template>
  <div class="homepage">
    <!-- 轮播图 -->
    <div height="378px" class="banner" v-loading="bannerLoading" v-show="bannerShow">
      <el-carousel height="378px" class="banner" style="margin-top:30px;">
        <el-carousel-item v-for="(item,index) in bannerList" :key="index">
          <el-image :src="item.image" fit="cover" class="banner-item"></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 已购课程 -->
    <div class="bought" v-if="boughtList.length">
      <h1>已购课程</h1>
      <div class="bought-box">
        <div class="bought-item hover" v-for="(item,index) in boughtList" @click="()=>to_detail(item.course_id)" :key="index">
          <div class="bought-item-t">
            <el-image :src="item.cover" class="bought-item-t" fit="cover"></el-image>
            <div class="bought-item-t-code">
              <span class="iconfont iconxiaobofang" style="font-size:12px;margin-right:4px;"></span>
              <span>{{item.visit_count}}</span>
            </div>
          </div>
          <div class="bought-item-b">
            <b class="ellipsis">{{item.title}}</b>
            <p>共{{item.chapter_count}}节</p>
          </div>
        </div>
      </div>
      <el-pagination layout="prev, pager, next" class="minePagination" hide-on-single-page v-if="minePagination.page" :current-page="minePagination.page" @current-change="mineCurrentChange" :page-size="minePagination.pageSize" :total="minePagination.total">
      </el-pagination>
    </div>
    <!-- 精选课程 -->
    <div class="selection" v-loading="selectionListLoading">
      <h1>
        <span>精选课程</span>
        <div class="sdkBtn hover" @click="showCDKEY">使用兑换码</div>
      </h1>
      <div class="selection-box">
        <div class="selection-item hover" @click="()=>to_detail(item.course_id,item.web_can_watch)" v-for="(item,index) in selectionList" :key="index">
          <el-image :src="item.cover" class="selection-item-image" fit="cover"></el-image>
          <div class="selection-item-content">
            <strong class="ellipsis">{{item.title}}</strong>
            <p class="ellipsis2">{{item.plain_description|trimSpace}}</p>
            <div class="selection-item-content-b">
              <span class="selection-item-content-b-l">共{{item.chapter_count}}节｜{{item.visit_count}}看过</span>
              <span class="selection-item-content-b-r">
                <span>{{item.price}}金币</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="homepage-pagination">
        <el-pagination background hide-on-single-page layout="prev, pager, next,jumper" :current-page="pagination.page" @current-change="currentChange" :page-size="pagination.pageSize" :total="pagination.total">
        </el-pagination>
      </div>
    </div>
    <cdkey-module ref="cdkeyModule" @success="cdkeySuccess" />
    <toAppModule ref="toAppModule" />
  </div>
</template>
 <script>
import cdkeyModule from "@/views/college/module/cdkeyModule"
import toAppModule from "@/views/college/module/toAppModule"
import userInfo from "@/mixins/userinfo"
export default {
  components: {
    cdkeyModule,
    toAppModule
  },
  data () {
    return {
      selectionListLoading: true,
      boughtListLoading: true,
      pagination: {
        total: 0,
        pageSize: 20,
        page: null
      },
      minePagination: {
        total: 0,
        pageSize: 20,
        page: null
      },
      bannerList: [],
      boughtList: [],
      selectionList: [],
      bannerShow: true,
      bannerLoading: true
    };
  },
  mixins: [userInfo],
  mounted () {
    this._get_list()
    this.userInfo && this.userInfo.token && this._get_minelist()
    this._get_bannerList()
  },
  methods: {
    //  显示cdkey 兑换模态框
    showCDKEY () {
      this.$refs.cdkeyModule.showModule()
    },
    to_detail (course_id, web_can_watch) {
      if (web_can_watch === 0) {
        this.$refs.toAppModule.showModule()
        return
      }
      // const resolve = (path, query) => {
      //   const { href } = this.$router.resolve({
      //     path,
      //     query
      //   });
      //   window.open(href, '_blank');//打开新的窗口
      // }
      // resolve("/homepage/collegedetial", { course_id })
      this.$router.push({
        name: "collegedetial",
        query: { course_id }
      })
    },
    currentChange (page) {
      this._get_list(page)
    },
    mineCurrentChange (page) {
      this._get_minelist(page)
    },
    async _get_list (page = 1) {
      this.selectionListLoading = true
      const { status, info, data, meta } = await this.$api.courseRecommendListApi({ page })
      this.selectionListLoading = false
      if (status == 200) {
        this.selectionList = data
        this.pagination.total = meta.pagination.total
        this.pagination.pageSize = meta.pagination.per_page
        this.pagination.page = meta.pagination.current_page
      } else {
        this.$message.error(info)
      }
    },
    async _get_minelist (page = 1) {
      this.boughtListLoading = true
      const { status, info, data, meta } = await this.$api.courseMineListApi({ page })
      this.boughtListLoading = false
      if (status == 200) {
        this.boughtList = data
        this.minePagination.total = meta.pagination.total
        this.minePagination.pageSize = meta.pagination.per_page
        this.minePagination.page = meta.pagination.current_page
      } else {
        this.$message.error(info)
      }
    },
    bannerClick ({ link }) {
      window.open(link, "_blank")
    },
    async _get_bannerList () {
      this.bannerLoading = true
      const { status, info, data } = await this.$api.getHomeBanners({ type: 6 })
      this.bannerLoading = false
      if (status == 200) {
        this.bannerList = [...data, ...data]
        this.bannerShow = data.length !== 0
      } else {
        this.$message.error(info)
        this.bannerShow = false
      }
    },
    // 验证码兑换成功回调函数
    cdkeySuccess () {
      this._get_minelist()
    }
  },
};
 </script>
 <style lang='scss' scoped>
.homepage {
  min-height: 100vh;
  width: 1240px;
  margin: 0 auto;
  padding-bottom: 50px;
  h1 {
    margin-top: 30px;
    color: #333333;
    line-height: 37px;
    font-size: 26px;
    font-weight: 500;
    font-family: PingFangSC-Medium, PingFang SC;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .sdkBtn {
      width: 108px;
      border-radius: 17px;
      border: 1px solid #2990f9;
      font-size: 16px;
      color: #2990f9;
      line-height: 34px;
      text-align: center;
    }
  }
  .banner {
    border-radius: 8px;
    overflow: hidden;
    &-item {
      width: 1240px;
      height: 378px;
    }
  }
  .bought {
    &-box {
      margin-top: 38px;
      display: flex;
      flex-wrap: wrap;
    }
    :nth-child(7n + 0) {
      margin-right: 0px;
    }
    &-item {
      width: 152px;
      height: 204px;
      margin-right: 29px;
      margin-bottom: 24px;
      &-t {
        width: 152px;
        height: 152px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        &-code {
          position: absolute;
          bottom: 0px;
          right: 0px;
          background: rgba(0, 0, 0, 0.6);
          border-radius: 4px 0px 0px 0px;
          color: #fff;
          padding: 2px 8px;
          font-size: 13px;
          line-height: 18px;
        }
      }
      &-b {
        p {
          color: #999999;
          line-height: 20px;
          font-size: 14px;
          margin-top: 4px;
        }
        b {
          color: #333333;
          line-height: 22px;
          font-size: 16px;
          margin-top: 6px;
        }
      }
    }
  }
  .selection {
    &-box {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      :nth-child(2n + 0) {
        margin-right: 0px;
      }
    }
    &-item {
      width: 608px;
      height: 188px;
      background: #ffffff;
      border-radius: 8px;
      border: 1px solid #eeeeee;
      margin-right: 24px;
      margin-bottom: 24px;
      padding: 18px;
      display: flex;
      &-image {
        width: 152px;
        height: 152px;
        border-radius: 8px;
      }
      &-content {
        margin-left: 20px;
        flex: 1;
        strong {
          margin-top: 6px;
          color: #333333;
          line-height: 28px;
          font-size: 20px;
        }
        p {
          margin-top: 6px;
          font-size: 14px;
          color: #666666;
          line-height: 20px;
          height: 40px;
        }
        &-b {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          &-l {
            color: #999999;
            line-height: 17px;
            font-size: 12px;
            margin-top: 10px;
          }
          &-r {
            color: #ff3b30;
            font-size: 22px;
            line-height: 30px;
            s {
              color: #999999;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
.homepage-pagination {
  display: flex;
  justify-content: center;
}
.minePagination {
  display: flex;
  justify-content: center;
  /deep/ .el-pager li,
  /deep/ button {
    background: transparent !important;
  }
}
</style>