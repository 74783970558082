/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-19 15:39:32
 * @Module: 上传视频
 */
<template>
  <div class="upVideoFile">
    <el-upload :action="action"
               list-type="picture-card"
               accept=".mp3,.wma,.avi,.rm,.rmvb,.flv,.mpg,.mov,.mkv,.mp4"
               :on-preview="handlePictureCardPreview"
               :on-remove="handleRemove"
               :data="dataObj"
               :limit="3"
               multiple
               :file-list="fileList"
               :on-change="change"
               :before-upload="upload"
                :class="{ hide: hideUploadEdit }"
               :on-exceed="exceed">
      <div class="upVideoFile-btn"
           slot="default">
      <i class="el-icon-plus" style="font-size:20px"></i>
        <div style="font-size:14px">上传视频</div>
      </div>
      <div slot="file"
           slot-scope="{file}"
           class="file-item">
        <video :src="file.url"
               width="100px"
               style="background:#000;"
               height="100px"></video>
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(file)">
            <i class="el-icon-zoom-in"></i>
          </span>
          <span class="el-upload-list__item-delete"
                @click="handleRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible"
               destroy-on-close>
      <video :src="dialogVideoUrl"
             width="100%"
             controls
             style="background:#000;"></video>
    </el-dialog>
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {
      action: '',
      dataObj: {
        success_action_status: '200',
      },
      dialogVisible: false,
      dialogVideoUrl: '',
      uidObject: {},
      hideUploadEdit: false, //是否隐藏图片上传框
    };
  },
  created () {
    this._getAliyun()
  },
  mounted () {

  },
   props: {
    fileList: {
      type: Array,
      default: () => []
    }
  },
  computed: {

  },
  methods: {
    // 获取阿里云配置
    async _getAliyun () {
      const { data: {
        policy,
        signature,
        accessid,
        host
      } } = await this.$api.getAliyun({ type: 'video' })
      this.action = host
      Object.assign(this.dataObj, {
        policy,
        signature,
        OSSAccessKeyId: accessid
      })
    },
    getFileName (filename) {    // 设置文件名字
      let getSuffix = (filename) => {
        let pos = filename.lastIndexOf('.');
        let suffix = '';
        if (pos != -1) {
          suffix = filename.substring(pos);
        }
        return suffix;
      };
      return (
        new Date().getTime() +
        Math.random()
          .toString(36)
          .substring(3, 20) +
        getSuffix(filename)
      );
    },
    upload (file) {   //文件上传前的回调
      const filename = this.getFileName(file.name)
      const copyFile = new File([file], `${filename}`)
      Object.assign(this.dataObj, {
        key: `video/${filename}`
      })
      this.$set(this.uidObject, file.uid, { path: `video/${filename}` })
      return copyFile
    },
    exceed () {  // 文件超出个数限制时的钩子
      this.$message.warning("最多只能上传3个视频")
    },
    change (file, fileList) {   // 文件列表改变
      this.$emit("setfileList",fileList)
        this.hideUploadEdit = this.fileList.length >= 3;
    },
    handlePictureCardPreview (file) {   //点击文件列表中已上传的文件时的钩子
      console.log(file)
      this.dialogVideoUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove (file, fileList) {  //文件列表移除文件时的钩子
      console.log(file, fileList)
      var newFileList = this.fileList.filter((item) => {
        return file.uid !== item.uid
      })
      this.$emit("setfileList",newFileList)
        this.hideUploadEdit = this.fileList.length >= 3;
      console.log(newFileList)
    },
  },
  watch: {
    fileList: {
      handler: function (val) {
        this.$emit("count", val.length)
      },
      // 深度观察监听
      deep: true,
      immediate: true
    }
  }
};
</script>
 <style lang='scss'>
.upVideoFile {
  &-btn {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #ccc;
    line-height: 20px;
    i {
      color: #ccc;
    }
  }
}
.file-item {
  position: relative;
}
</style>