/* 
 * @Author: 曹俊杰 
 * @Date: 2021-01-23 14:36:10
 * @Module: 兑换cdk 模态框
 */
 <template>
  <div>
    <el-dialog title="兑换码验证"
               :visible.sync="dialogVisible"
               width="30%">
      <el-input placeholder="请输入兑换码"
                clearable
                v-model="code">
        <el-button slot="append"
                   @click="verifyClick">验证兑换码</el-button>
      </el-input>
      <div class="text"
           v-show="text">《{{text}}》</div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false"
                   size="mini">取 消</el-button>
        <el-button type="primary"
                   size="mini"
                   @click="cashCode">确 认 兑 换</el-button>
      </span>
    </el-dialog>
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {
      dialogVisible: false,
      code: "",
      text: ''
    };
  },
  mounted () { },
  methods: {
    okBtn () {
      this.dialogVisible = false
    },
    showModule () {
      this.dialogVisible = true
    },
    // 验证兑换码点击事件
    async verifyClick () {
      const { status, data, info } = await this.$api.verifyExchangeApi({ code: this.code })
      if (status == 200) {
        this.text = data.product_title
      } else {
        this.$message.warning(info)
      }
    },
    // 兑换码兑换
    async cashCode () {
      const { status, info } = await this.$api.exchangeCodeCourseApi({ code: this.code })
      if (status == 200) {
        this.$emit("success")
        this.dialogVisible = false
        this.$message.success(info)
      } else {
        this.$message.warning(info)
      }
    }
  },
};
 </script>
 <style lang='scss' scoped>
.text {
  line-height: 28px;
  color: #333333;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}
</style>