<template>
  <div class="bigbox">
    <div class="mine">
      <div class="mone" @click="toTui(1)">
        <div class="top" style="position: relative;">
          <div class="Iimg">
            <img src="@/static/starImg/anchor.png" />
          </div>
          <span style="margin-right:36px">我的主播</span>
          <i class="el-icon-arrow-right"></i>
        </div>
        <div class="olone"></div>
        <div class="bot">
          <div class="bbot">
            <div class="p1">
              <span>主播：</span>
              <span>
                {{ xtAccount.anchor_count }}
                <span>人</span>
              </span>
            </div>
            <div class="p1 p2">
              <span>推荐记录：</span>
              <span>
                {{ xtAccount.recommend_count }}
                <span>次</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="mone m1" @click="toTui(2)">
        <div class="top" style="position: relative;">
          <div class="Iimg">
            <img src="@/static/starImg/money.png" />
          </div>
          <span style="margin-right:36px">我的收益</span>
          <i class="el-icon-arrow-right"></i>
        </div>
        <div class="olone"></div>
        <div class="bot">
          <div class="bbot">
            <div class="p1">
              <span>余额：</span>
              <span>
                {{ xtAccount.balance }}
                <span>元</span>
              </span>
            </div>
            <div class="p1 p2">
              <span>累计收益：</span>
              <span>
                {{ xtAccount.total_income }}
                <span>元</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    xtAccount: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    toTui(num) {
      if (num == 1)
        this.$router.push({
          path: `/star/anchor`,
          query: {
            tab: 1
          }
        });
      if (num == 2) this.$router.push("/star/shouyi");
    }
  }
};
</script>
<style lang="scss" scoped>
.mine {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  .mone {
    width: 48%;
    background: linear-gradient(90deg, #53c3fd 0%, #2990f9 100%);
    box-shadow: 2px 2px 6px 0px rgba(83, 195, 253, 0.25);
    border-radius: 4px;
    color: #fff;
    margin-bottom: 30px;
    .top {
      font-size: 13px;
      width: 90%;
      margin-left: 5%;
      height: 40px;
      line-height: 40px;
      .Iimg {
        width: 16px;
        height: 16px;
        float: left;
        margin-left: 3%;
        margin-top: 4px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      span {
        float: left;
        margin-left: 14px;
      }
    }
    .olone {
      width: 92%;
      margin: auto;
      height: 1px;
      background: linear-gradient(
        180deg,
        #ffffff 1%,
        #ffffff 56%,
        #ffffff 100%
      );
      border-radius: 1px;
      opacity: 0.5;
    }
    .bot {
      width: 100%;
      padding: 0 5%;
      height: 80px;
      background-image: url(../../../static/starImg/wave.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .bbot {
        width: 94%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .p1 {
          display: flex;
          justify-content: space-between;
          font-size: 15px;
          font-weight: 500;
          color: #ffffff;
          span {
            span {
              font-size: 11px;
            }
          }
        }
        .p2 {
          font-size: 13px;
          color: #ffffff;
          line-height: 16px;
          margin-top: 10px;
        }
      }
    }
  }
  .m1 {
    background: linear-gradient(135deg, #ffd46d 0%, #ff8f00 100%);
    box-shadow: 2px 2px 6px 0px rgba(255, 143, 0, 0.25);
  }
}
.bigbox{
  margin-top: 16px;
  background: #f7f7f7;
}
</style>