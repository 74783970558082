/* 
 * @Author: 曹俊杰 
 * @Date: 2020-09-09 10:48:21
 * @Module: 查询详情
 */
 <template>
  <div class="certification">
    <resultheader search style="box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);background:#fff;" />
    <div class="certification-content">
      <div class="certification-detail">
        <div class="certification-detail-top">
          <div class="certification-detail-back pointer" @click="$router.go(-1)">
            <i class="iconfont iconfanhui"></i>
            返回
          </div>
          <div class="certification-detail-top-content">
            <div class="certification-detail-top-content-l">
              <div class="certification-detail-top-content-l-t">中能魔力（河南）洛阳科技有限公司</div>
              <div class="certification-detail-top-content-l-i">续存</div>
              <div class="certification-detail-top-content-l-te">
                <span>
                  法人代表：
                  <span>赵有才</span>
                </span>
                <span>所属行业：软件和信息技术服务业</span>
              </div>
              <div class="certification-detail-top-content-l-te">
                <span>联系电话：15896559095</span>
                <span>地址：河南省洛阳市涧西区万国银座1902</span>
              </div>
            </div>
            <div class="certification-detail-top-content-r">河南九鲸</div>
          </div>
          <div class="certification-detail-top-bottom">
            <div class="certification-detail-top-bottom-l">
              <div>business</div>
              <div>业务介绍</div>
              <div>计算机领域的技术开发、技术转让、技术咨询和技术服务；第二类增值电信业务；网络工程；计算机系统集成；文化娱乐经纪人服务；文艺创作与表演；国内演出经纪代理服务，动画（漫画）经纪代理服务；大型活动组织服务；会议及展览展示服务；企业形象设计；设计、制作、代理、发布国内各类广告；产品包装设计，图文设计；网站建设；动漫设计。</div>
              <dl class="line1">
                <dt></dt>
                <dt></dt>
              </dl>
              <dl class="line2">
                <dt></dt>
                <dt></dt>
              </dl>
              <dl class="line3">
                <dt></dt>
              </dl>
            </div>
            <div class="certification-detail-top-bottom-r"></div>
          </div>
        </div>
        <div class="certification-detail-bottom">
          <div v-for="(item,index) in 30" :key="index"></div>
        </div>
      </div>
    </div>
  </div>
</template>
 <script>
import resultheader from "./header";
export default {
  data() {
    return {};
  },
  components: {
    resultheader,
  },
  mounted() {
		window.parent.scrollTo(0, 0);
	},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
.certification-detail {
  width: 1240px;
  margin-top: 30px;
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  &-back {
    line-height: 58px;
    padding-left: 30px;
    font-size: 14px;
    color: #999;
  }
  &-top {
    height: 559px;
    background: url("/static/certificationImg/bg_3.png") no-repeat;
    background-size: 1100px;
    background-position: right top;
    &-content {
      display: flex;
      padding: 0 40px;
      justify-content: space-between;
      margin-top: 20px;
      align-items: center;
      &-l {
        width: 502px;
        height: 106px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &-t {
          font-size: 22px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #333333;
        }
        &-i {
          line-height: 18px;
          background: rgba(41, 144, 249, 0.2);
          width: 36px;
          text-align: center;
          color: #2990f9;
          border-radius: 2px;
          font-size: 12px;
        }
        &-te {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          color: #333;
          span:last-child {
            min-width: 256px;
          }
          span {
            span {
              color: #2990f9;
            }
          }
        }
      }
      &-r {
        width: 100px;
        height: 100px;
        background: #2990f9;
        border-radius: 6px;
        padding: 14px;
        box-sizing: border-box;
        color: #fff;
        font-size: 36px;
      }
    }
    &-bottom {
      height: 305px;
      margin-top: 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 40px;
      &-l {
        width: 550px;
        height: 215px;
        color: #666;
        font-size: 18px;
        position: relative;
        div:nth-child(1) {
          margin-top: 12px;
        }
        div:nth-child(2) {
          margin-top: 10px;
          font-weight: bold;
        }
        div:nth-child(3) {
          font-size: 14px;
          margin-top: 18px;
          line-height: 24px;
        }
        dl {
          position: absolute;
          display: block;
          height: 2px;
          line-height: 2px;
          dt {
            margin: 0;
            height: 2px;
            border-radius: 1px;
            opacity: 0.4;
            background: #2990f9;
            display: inline-block;
          }
        }
        .line1 {
          top: 0;
          left: 0;
          dt:nth-child(1) {
            width: 14px;
          }
          dt:nth-child(2) {
            width: 8px;
            margin-left: 4px;
          }
        }
        .line2 {
          top: 56px;
          right: 0;
          dt:nth-child(1) {
            width: 14px;
          }
          dt:nth-child(2) {
            width: 410px;
            margin-left: 4px;
          }
        }
        .line3 {
          dt {
            width: 20px;
          }
        }
      }
      &-r {
        width: 542px;
        height: 305px;
        border-radius: 6px;
        background: #eee;
      }
    }
  }
  &-bottom {
		padding: 30px 40px;
		padding-bottom: 0px;
    display: flex;
    flex-wrap: wrap;
    div {
      width: 256px;
      height: 144px;
      border-radius: 6px;
      background: #eee;
      margin-bottom: 30px;
      margin-left: 45px;
    }
    div:nth-child(4n + 1) {
      margin-left: 0px;
    }
  }
}
</style>