export default [{
	name: '爆米花',
	phone: '13252014448',
	email: '344388262@qq.com',
	wechart: 'sxd_0128',
	img: 'static/staffimg/baomihua.png'
},
{
	name: '苍茂霖',
	phone: '17640535536',
	email: '1134450611@qq.com',
	wechart: 'cmlin0204',
	img: 'static/staffimg/cang.png'
},
{
	name: '岳琪琪',
	phone: '18741954179',
	email: 'laojieyuta@163.com',
	wechart: '18741954179',
	img: 'static/staffimg/yueqiqi.png'
},
{
	name: '王不服',
	phone: '19904999666',
	email: '282668343@qq.com',
	wechart: '13322339210',
	img: 'static/staffimg/19904999666.png'
},
{
	name: '王贤',
	phone: '19139616660',
	email: '1083947350@qq.com',
	wechart: 'yixichuanwei',
	img: 'static/staffimg/wangxian.png'
},
{
	name: '灯花',
	phone: '18663622890',
	email: '377404733@qq.com',
	wechart: 'Zheng2856711',
	img: 'static/staffimg/denghua.png'
},
{
	name: '奶茶',
	phone: '15064479423',
	email: '281529307@qq.com',
	wechart: 'LX281529307',
	img: 'static/staffimg/naica.png'
},
{
	name: '刘文升',
	phone: '17853255410',
	email: '18253972741@163.com',
	wechart: 'Noah_biu',
	img: 'static/staffimg/liuwensheng.png'
},
{
	name: '纪树宇',
	phone: '15304182888',
	email: 'kenzo.han@fx418.com',
	wechart: '15304182888',
	img: 'static/staffimg/laoji.png'
},
{
	name: '于洋',
	phone: '17174468888',
	email: '626062649@qq.com',
	wechart: '17174468888',
	img: 'static/staffimg/yuyang.png'
},
{
	name: '李要磊',
	phone: '13603791010',
	email: '996809770@qq.com',
	wechart: 'Vleivvvvvv',
	img: 'static/staffimg/liyaolei.png'
},
{
	name: '黄飞',
	phone: '18238885556',
	email: '854329608@qq.com',
	wechart: 'n1conqueror',
	img: 'static/staffimg/huangfei.png'
},
{
	name: '付伟祺（葱哥）',
	phone: '17739033203',
	email: '2606499101@qq.com',
	wechart: 'vip-congge',
	img: 'static/staffimg/whttLogo2.png'
},
{
	name: '江浪',
	phone: '19162968888',
	email: '524272163@qq.com',
	wechart: 'jiang6712270',
	img: 'static/staffimg/jiangliang.png'
},
{
	name: '孙一鸣',
	phone: '13435005555',
	email: '71010999@qq.com',
	wechart: '13435005555',
	img: 'static/staffimg/sunhe.png'
},
{
	name: '姜雅潞',
	phone: '15945344399',
	email: '1721784527@qq.com',
	wechart: 'v595521',
	img: 'static/staffimg/jiangyalu.png'
},
{
	name: '王伟',
	phone: '15868230999',
	email: 'anqiyao-7@163.com',
	wechart: 'gary07',
	img: 'static/staffimg/wangwei.png'
},
{
	name: '大表哥',
	phone: '13716649888',
	email: '83110969@qq.com',
	wechart: 'whtoutiao888',
	img: 'static/staffimg/dabiaoge.png'
},
{
	name: '大萱',
	phone: '17260647939',
	email: '3601664385@qq.com',
	wechart: 'abcjlj315',
	img: 'static/staffimg/xuanxuan.png'
},
{
	name: '郑昊',
	phone: '18863319521',
	email: '1559788920@qq.com',
	wechart: 'Ahao58777',
	img: 'static/staffimg/zhenghao.png'
},
{
	name: '向日葵',
	phone: '18756080532',
	email: '56034816@qq.com',
	wechart: 'qqyy515626',
	img: 'static/staffimg/xiangrikui.png'
},
{
	name: '邹有招',
	phone: '18863315415',
	email: '929869192@qq.com',
	wechart: 'ZYC_05',
	img: 'static/staffimg/cuyouzhao.png'
},
{
	name: '张春姣',
	phone: '18647459586',
	email: '513670476@qq.com',
	wechart: 'xiaogudejia111',
	img: 'static/staffimg/18647459586.png'
},
{
	name: '崔梦一',
	phone: '15538845580',
	email: 'cuimengyi197@qq.com',
	wechart: '15538845580',
	img: 'static/staffimg/15538845580.png'
},
{
	name: '李斯文',
	phone: '18049886661',
	email: '987432326@qq.com',
	wechart: '18049886661',
	img: 'static/staffimg/18049886661.png'
},
{
	name: '程雪峰',
	phone: '15941666760',
	email: '632681295@qq.com',
	wechart: 'Mustang2727',
	img: 'static/staffimg/15941666760.png'
}
]
