/* 
 * @Author: 曹俊杰 
 * @Date: 2020-12-14 16:36:02
 * @Module:消息中心的导航
 */
<template>
  <div class="notice-box">
    <!-- <div class="notice-item">消息中心</div> -->
    <div class="notice-item hover"
         :class="active==item.key?'notice-navAc':'notice-navDf'"
         v-for="(item,index) in list"
         @click="$router.push({name:item.key})"
         :key="index">{{item.title}}</div>
  </div>
</template>
<script>
export default {
  components: {},
  data () {
    return {
      list: [
        {
          title: '消息中心',
          key: "notice"
        },
        {
          title: '我的钱包',
          key: 'wallet'
        },
        {
          title: "学习中心",
          key: 'college'
        }
      ],
      active: 'notice'
    };
  },
  mounted () { },
  methods: {},
  watch: {
    $route: {
      handler: function (val) {
        this.active = val.meta.noticeActive
      },
      // 深度观察监听
      deep: true,
      immediate: true
    }
  },
};
</script>
<style lang='scss' scoped>
.notice-box {
  height: 174px;
  width: 100%;
  margin-top: 10px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
}
.notice-item {
  height: 58px;
  line-height: 58px;
  text-align: center;
  font-size: 18px;
  color: #666;
}
.notice-navAc {
  color: #df2a29;
}
.notice-navDf {
  color: #999999;
  border-left: 2px solid #fff;
}
</style>