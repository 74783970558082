<template>
  <div class="bgImg">
    <img src="@/static/img/laboratory/IconBg.png"
         alt="">
    <div class="systemBox">
      <div style="font-size:30px;font-weight:500;">
        -系统特点-
      </div>
      <div class="imgBox"
           style="height:331px;width:1100px;margin-top:67px;margin-bottom: 76px;">
        <div class="textImgBox">
          <div class="iocnImg">
            <img src="@/static/img/laboratory/profit.png"
                 alt="">
          </div>
          <div class="textTitle">
            收益结算
          </div>
          <div class="textContent">
            结算秒到账<br>真正打破信任危机
          </div>
        </div>
        <div class="textImgBox">
          <div class="iocnImg">
            <img src="@/static/img/laboratory/logic.png"
                 alt="">
          </div>
          <div class="textTitle">
            数据逻辑
          </div>
          <div class="textContent">
            主播管理轻松<br>直观、为业务助力
          </div>
        </div>
        <div class="textImgBox">
          <div class="iocnImg">
            <img src="@/static/img/laboratory/guidance.png"
                 alt="" />
          </div>
          <div class="textTitle">
            全程指导
          </div>
          <div class="textContent">
            帮助新人和小<br>团队快速成长
          </div>
        </div>
      </div>
      <img class="downArrow"
           src="@/static/img/laboratory/downArrow.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: "laboratorySystem",
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
<style  scoped>
.bgImg {
  width: 100%;
  position: relative;
}
.bgImg img {
  width: 100%;
  height: 645px;
}
.systemBox {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  height: 100%;
  /* margin: 87px 227px 80px 227px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.imgBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 340px;
  width: 1100px;
  margin-top: 50px;
  margin-bottom: 76px;
}
.downArrow {
  width: 22px !important;
  height: 19px !important;
}
.textImgBox {
  width: 310px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 60px;
  padding-bottom: 62px;
  text-align: center;
}
.iocnImg {
  width: 110px;
  height: 110px;
}
.iocnImg img {
  width: 100%;
  height: 100%;
}
.textTitle {
  color: #333333;
  font-size: 18px;
  font-size: 500;
  margin-top: 36px;
  margin-bottom: 14px;
}
.textContent {
  width: 403px;
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
@media screen and (max-width: 1240px) {
  .bgImg {
    min-width: 1240px;
  }
}
</style>