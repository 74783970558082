<template>
    <div></div>
</template>
<script>
// import tjHeader from '@components/public/tjHeader'
export default {
    
}
</script>
<style lang="scss" scoped>

</style>